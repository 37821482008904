import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentArea from "../components/contentArea"
import Heading from "../components/heading"

const AboutPage = () => (
    <Layout pageName="about">
        <ContentArea>
            <Heading {...{ level: 1, content: "Services" }} />
			<p>
				<b>Counselling &amp; Psychotherapy:</b> Our clinic offers counselling support for depression, anxiety, family conflicts, and couples/relationship difficulties for people of all ages and backgrounds, with each practitioner having a particular area of focus or interest. Both individual &amp; group modalities are available. Various evidence-based psychotherapy modalities are offered depending on the provider, including:
				<br/><br/>
			</p>
			<ul>
				<li>Cognitive-Behavioral Therapy</li>
				<li>Dialectical Behavioural Therapy Skills</li>
				<li>Emotion-Focused Therapy</li>
				<li>Eye Movement Desensitization and Reprocessing (EMDR)</li>
				<li>Couples Therapy</li>
				<li>Family Therapy</li>
			</ul>
			<p style={{ textAlign: "center" }}>
				<br/>
				<b>Please note: OHIP-covered psychotherapy services (i.e. therapy provided by psychiatrists/physicians) are extremely limited due to the high demand. </b>
				<br/><br/>
			</p>
			<p>
				<b>Psychiatric &amp; Mental Health Assessments:</b> One-time consultation for various age groups and conditions are available depending on the physician (Dr. Chen, Dr. Lo or Dr. Yang). These assessments can help provide diagnostic clarification, medication recommendations, and specific suggestions for areas to focus on in psychotherapy (individual and/or group therapy).
				<br/><br/>
				<b>Psychoeducational Assessments:</b> Psychoeducational assessments provided by our psychologist, Dr. Lin, can help provide diagnostic clarity, and help clients advocate for disability benefits or accommodations at school or work. Customized psycho-educational assessments are used to evaluate the various learning styles or processing differences that may impact a person's effectiveness at school, work, and in day to day functioning, providing clarification on the following:
				<br/><br/>
			</p>
			<ul>
				<li>Giftedness</li>
				<li>ADHD</li>
				<li>Autism</li>
				<li>Learning disabilities</li>
				<li>Intellectual disabilities</li>
				<li>Behavioral/emotional issues</li>
				<li>Mental health issues</li>
			</ul>
			<p>
				<br/>
				Please check your work health insurance plan to see if you have coverage for psychological services by a registered psychologist. Many plans will cover most of the cost of these psycho-educational assessments. If these services are of interest to you, please contact Dr. Lin at <a href="mailto:drlinandassociates@gmail.com">drlinandassociates@gmail.com</a>.
			</p>
        </ContentArea>
    </Layout>
)

export const Head = () => <Seo title="About Us" />

export default AboutPage
